import Container from 'components/contaienr'
import Footer from 'components/footer'
import FullPageSpinner from 'components/full-page-spinner'
import Router from 'pages/router'
import React from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'

function App() {
  return (
    <>
      <div className='bg-primary'>
        <Container>
          <div className='flex justify-end'>
            <div className='text-white py-3'>
              <Link to='/contact' className='mr-4'>
                Contact
              </Link>
              <Link to='/location'>Location</Link>
            </div>
          </div>
        </Container>
      </div>
      <React.Suspense fallback={<FullPageSpinner />}>
        <Router />
      </React.Suspense>
      <Footer />
    </>
  )
}

export default App
