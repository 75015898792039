export function Heading({ children, className = '', ...rest }) {
  return (
    <h2
      className={`text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold ${className}`}
      {...rest}
    >
      {children}
    </h2>
  )
}
