import greenLogo from 'assets/images/home/golden-logo-2.png'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { NavLink } from 'react-router-dom'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

function SideNavbar({ open, handleOpen }) {
  return (
    <Drawer open={open} onClose={handleOpen} direction='left' className='lg:hidden'>
      <div className='px-4 pt-6 h-full'>
        <LazyLoadImage
          effect='blur'
          className='w-[180px] mx-auto block h-auto'
          src={greenLogo}
          alt='logo'
        />
        <ul className='mt-8'>
          <li>
            <NavLink
              to='/'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/segam-dates'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Dates
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/segam-herbs'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Herbs
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to='/segam-trade'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Trades
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to='/segam-farm'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Farm
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to='/segam-invest'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Invest
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to='/what-we-do'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              What We DO
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/careers'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Careers
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/customers'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Customers
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/suppliers'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Suppliers
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/sustainability'
              className={({ isActive }) =>
                ` block w-full hover:bg-green-100 hover:text-green-600 transition-all duration-500 py-2 px-4  rounded ${
                  isActive
                    ? 'text-green-600 bg-green-100 font-semibold'
                    : 'text-tertiary font-medium'
                }`
              }
            >
              Sustainability
            </NavLink>
          </li>
        </ul>
      </div>
    </Drawer>
  )
}

export default SideNavbar
