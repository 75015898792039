export const navData = [
  {
    id: 1,
    name: 'Home',
    link: '/',
  },
  {
    id: 2,
    name: 'Dates',
    link: '/segam-dates',
  },
  {
    id: 3,
    name: 'Herbs',
    link: '/segam-herbs',
  },
  // {
  //   id: 4,
  //   name: 'What We Do',
  //   link: '/what-we-do',
  // },
  // {
  //   id: 5,
  //   name: 'Farm',
  //   link: '/segam-farm',
  // },
  // {
  //   id: 6,
  //   name: 'Invest',
  //   link: '/segam-invest',
  // },
]
