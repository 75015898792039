import { TextField } from './form-elements'

export default function ContactForm({ className = 'bg-primary hover:bg-green-600' }) {
  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='col-span-12 lg:col-span-5'>
        <h3 className='text-2xl font-bold text-secondary mb-4'>Contact Information</h3>
        <p className='text-tertiary font-medium mt-2 mb-4'>
          If you would like to send us a message, please fill out the form below and we will get
          back to you as soon as possible.
        </p>
        <p className='text-tertiary font-medium mt-2'>
          <span className='font-bold'>Address : </span> Central axis, 6 October, October plaza, 405
          D, 6&apos;october city, Giza, Egypt
        </p>
        <p className='text-tertiary font-medium mt-2'>
          {' '}
          <span className='font-bold'>Phone: </span> (+20) 2 38367340/9
        </p>
        <p className='text-tertiary font-medium mt-2'>
          {' '}
          <span className='font-bold'>Email : </span> info@segamgroup.com
        </p>
      </div>
      <div className='col-span-12 lg:col-span-7'>
        <form>
          <TextField type='text' label='Name' placeholder='Enter name' className='mb-4' />
          <TextField type='email' label='Email' placeholder='Enter email' className='mb-4' />
          <TextField type='text' label='Phone' placeholder='Enter phone' className='mb-4' />
          <TextField
            label='Message'
            placeholder='Write a message'
            className='mb-4'
            type='textarea'
          />
          <button
            className={`text-white font-medium text-md 2xl:text-lg py-3 px-8 rounded-md ${className}`}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
