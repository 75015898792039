/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

function Label({ className, ...labelProps }) {
  return (
    <label
      {...labelProps}
      className={`block text-black font-semibold text-base 2xl:text-lg mb-1 ${className}`}
    />
  )
}

const Input = React.forwardRef(function Input(props, ref) {
  const className = `block w-full bg-gray-100 py-4 px-4 placeholder:text-slate-500 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-primary text-base 2xl:text-xl ${props.className}`

  if (props.type === 'textarea') {
    return <textarea {...props} className={className} cols='4' />
  }

  return <input {...props} className={className} ref={ref} />
})

export function TextField({ defaultValue = '', name, label, className, ...rest }) {
  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <Input defaultValue={defaultValue} {...rest} id={name} className={className} />
    </div>
  )
}
